<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Admin Users</h5>
                <div v-if="userdatadisplayflag && host_id == ''">
                    <Button label="Create User" @click="openCreateUserPopUp()"></Button>
                </div>
            </div>
            <div class="card">
                <div class="p-d-flex p-ai-center p-flex-column p-flex-lg-row p-mb-1">
                    <div class="p-d-flex p-mr-3">
                        <Dropdown v-model="emp_name" :options="adminList" @change="getAdminEmpDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select admin"
                            :style="{ minWidth: '200px' }" />
                    </div>
                    <div class="p-d-flex p-mr-3">
                        <Dropdown v-model="host_name" :options="hostList" @change="getHostEmpDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select host"
                            :style="{ minWidth: '200px' }" />
                    </div>
                    <div class="p-d-flex p-mr-3 ">
                        <Dropdown v-model="branch_name" :options="branchList" @change="getHostBranchEmpDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select branch"
                            :style="{ minWidth: '200px' }" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <TabView v-if="userdatadisplayflag">
        <TabPanel header="User Details">
            <div class="card">
                <DataTable :value="EmpDetailsList" :lazy="true" :paginator="true" :rows="30" :totalRecords="EmpTotalRecords"
                    :loading="loading" @page="onPageChange" class="p-datatable-users" data-key="ad1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :style="{ 'overflow-wrap': 'break-word' }">
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait... </template>
                    <Column header="User Name" headerStyle="width: 27%">
                        <template #body="{ data }">
                            <div class="p-d-flex p-ai-center">
                                <div class="p-mr-3">
                                    <span v-if="data.ad19 != null && data.ad19 != ''">
                                        <img :src="data.ad19" width="50" height="50"
                                            style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                    <span v-else>
                                        <img src="/assets/layout/images/widgets/asset-profile-11.png" width="50" height="50"
                                            style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                </div>
                                <div>
                                    <div class="p-text-capitalize overflow-span">{{ data.ad9 ? data.ad9
                                        : 'N/A' }}</div>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Our Role" headerStyle="width: 28%">
                        <template #body="{ data }">
                            <span v-if="data.ad28 != '' && data.ad28 != null && data.ad28 != '0'"><span
                                    v-for="(value, index) in getAssignedRoles(data.ad28).split(',')" :key="index">
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" :label="value" />
                                </span></span>
                            <span v-else>-</span>
                        </template>
                    </Column>
                    <Column header="Email Id" headerStyle="width: 18%">
                        <template #body="{ data }">
                            <span class="overflow-span">{{ data.ad18 ? data.ad18 : "-" }}</span>
                        </template>
                    </Column>
                    <Column header=" Contact Number" headerStyle="width: 12%">
                        <template #body="{ data }">
                            <span class="overflow-span">{{ data.ad15 ? data.ad15 : "-" }}</span>
                        </template>
                    </Column>
                    <Column header="Status" headerStyle="width: 6%" class="p-text-center">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <div v-if="data.ad44 == 1"> Active </div>
                                <div v-else-if="data.ad44 == 2"> Temporary suspended </div>
                                <div v-else> Permanently suspended</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 6%" class="p-text-center"
                        v-if="userdatadisplayflag && host_id == ''">
                        <template #body="{ data }">
                            <div>
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                    @click="editAdminUserPopUPOpen(data)" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </TabPanel>
    </TabView>
    <!-- Add/Update Admin User Dialog starts here -->
    <Dialog v-model:visible="openCreateUserPopUpStatus" :style="{ width: '900px' }" :modal="true" class="p-fluid"
        :header="ad1 != null ? 'Update User' : 'Create User'">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="usermobile">
                        Mobile No.
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            +91
                        </span>
                        <InputText id="usermobile" v-model="usermobile" autocomplete="off" maxlength="10"
                            @keypress="onlyNumber" @keyup="checkMobileNumber(usermobile)" autofocus
                            :class="{ 'p-invalid': submitted && !usermobile }" :disabled=checkMobileAlreadyExist />
                    </div>
                    <small class="p-invalid p-error" v-if="v$.usermobile.$error">{{
                        v$.usermobile.$errors[0].$message
                    }}</small>
                    <small class="p-invalid p-error" v-if="this.warningMsg != ''">{{
                        this.warningMsg
                    }}</small>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid" v-if="checkMobileAlreadyExist">
                <div class="p-field p-col-12 p-md-4">
                    <label for="title">
                        Title
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="title" v-model="title" :options="titleList" optionLabel="label" placeholder="Select"
                        appendTo="body">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.title.$error">{{ v$.title.required.$message }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="userfirstname">
                        First Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="userfirstname" class="p-text-capitalize" v-model.trim="userfirstname" required="true"
                        maxlength="30" autofocus :class="{ 'p-invalid': submitted && !userfirstname }" />
                    <small class="p-invalid p-error" v-if="v$.userfirstname.$error">{{
                        v$.userfirstname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="usermiddlename">
                        Middle Name
                    </label>
                    <InputText id="usermiddlename" class="p-text-capitalize" v-model.trim="usermiddlename" maxlength="30"
                        autofocus :class="{ 'p-invalid': submitted && !usermiddlename }" />
                    <small class="p-invalid p-error" v-if="v$.usermiddlename.$error">{{
                        v$.usermiddlename.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="userlastname">
                        Last Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="userlastname" class="p-text-capitalize" v-model.trim="userlastname" required="true"
                        maxlength="30" autofocus :class="{ 'p-invalid': submitted && !userlastname }" />
                    <small class="p-invalid p-error" v-if="v$.userlastname.$error">{{
                        v$.userlastname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="user-email">
                        Email
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="user-email" v-model.trim="userEmail" required="true" autocomplete="usremail"
                        maxlength="70" autofocus :class="{ 'p-invalid': submitted && !userEmail }" />
                    <small class="p-invalid p-error" v-if="v$.userEmail.$error">{{
                        v$.userEmail.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>Gender <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="Male" name="option" v-bind:value="1" v-model="gender" :checked="gender == 1" />
                        <label for="Male" class="p-mr-2">Male</label>
                        <RadioButton id="Female" name="option" v-bind:value="2" v-model="gender" :checked="gender == 2" />
                        <label for="Female" class="p-mr-2">Female</label>
                        <RadioButton id="Other" name="option" v-bind:value="3" v-model="gender" :checked="gender == 3" />
                        <label for="Other" class="p-mr-2">Other</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.gender.$error">{{
                        v$.gender.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="statesList" optionLabel="label"
                        placeholder="Select State" :filter="true" @change="selectAdminCity($event)" appendTo="body">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{
                        v$.state.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="city">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="city" v-model="city" :options="citiesList" optionLabel="label" placeholder="Select City"
                        :filter="true" @change="selectAdminArea($event)" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.city.$error">{{
                        v$.city.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="area">
                        Area
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <Dropdown id="area" v-model="area" :options="areaList" optionLabel="label" placeholder="Select Area"
                        :filter="true" @change="onAreaChange($event)" appendTo="body"></Dropdown>
                    <!-- <small class="p-invalid p-error" v-if="v$.area.$error">{{
                        v$.area.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-8">
                    <label for="address">
                        Address
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Textarea id="address" v-model="address" :autoResize="true" rows="2" cols="30"
                        placeholder="Enter Full Address" maxlength="250" />
                    <small class="p-invalid p-error" v-if="v$.address.$error">{{
                        v$.address.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="pincode">
                        Pin Code
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <div class="p-inputgroup">
                        <InputText id="pincode" v-model="pincode" autocomplete="off" maxlength="6" @keypress="onlyNumber"
                            autofocus :class="{ 'p-invalid': submitted && !pincode }" />
                    </div>
                    <small class="p-invalid p-error" v-if="v$.pincode.$error">{{
                        v$.pincode.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>Upload Profile </label>
                    <FileUpload mode="basic" ref="profileImage" accept="image/*" :maxFileSize="1000000"
                        chooseLabel="Upload Profile" auto @select="onSelectProfile">
                    </FileUpload>
                    <span v-if="profileUrl == null">
                        <img v-if="userprofilefile" class="p-pt-2" role="presentation" alt="userprofile87878"
                            :src="userprofilefile" width="120" height="120" />
                    </span>
                    <img v-if="profileUrl" class="p-pt-2" role="presentation" :alt="profileUrl.name" :src="profileUrl.url"
                        width="120" height="120" />
                    <small class="p-invalid p-error" v-if="profileImageError">{{ profileImageError }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="role">
                        Role
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="role" v-model="role" :options="adminRoleList" optionLabel="label"
                        placeholder="Select Role" appendTo="body">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.role.$error">{{ v$.role.required.$message }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="dof">
                        Date of Birth
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="dob" :maxDate="subdate"
                        placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" appendTo="body" :manualInput="false"
                        :yearRange="'1950:' + subdate.getFullYear()">
                    </Calendar>
                    <small class="p-invalid p-error" v-if="v$.dob.$error">
                        {{ v$.dob.$errors[0].$message }}
                    </small>
                </div>
            </div>
        </div>
        <template #footer>
            <Button v-show="!showLoader" :label="ad1 != null ? 'Update' : 'Create'" icon="pi pi-check"
                class="p-button-text p-ml-auto" @click="createUpdateAdminUser" style="width: 90px;"></Button>
            <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- Add/Update Admin User Dialog end here -->
</template>
<script>
import ApiService from '../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, alpha, helpers, minLength, maxLength, numeric, email } from '@vuelidate/validators';
import moment from 'moment';

export default {
    data() {
        return {
            v$: useValidate(),
            adminList: [],
            hostList: [],
            branchList: [],
            page_no: 0,
            EmpTotalRecords: 0,
            loading: false,
            EmpDetailsList: [],
            emp_name: '',
            host_name: '',
            branch_name: '',
            userdatadisplayflag: false,
            admin_id: '',
            host_id: '',
            branch_id: '',
            admin_list_status: false,
            host_list_status: false,
            branch_list_status: false,
            itemsPerPage: 30,
            adminRoleList: [],
            openCreateUserPopUpStatus: false,
            title: '',
            titleList: [],
            userfirstname: '',
            usermiddlename: '',
            userlastname: '',
            usermobile: '',
            userEmail: '',
            gender: '',
            statesList: [],
            citiesList: [],
            areaList: [],
            state: '',
            city: '',
            area: '',
            address: '',
            pincode: '',
            role: '',
            profileUrl: null,
            profileImage: null,
            profileImageError: '',
            userprofilefile: null,
            dob: '',
            todaysDate: new Date(),
            checkMobileAlreadyExist: false,
            submitted: false,
            showLoader: false,
            stateID: 0,
            warningMsg: '',
            age: 0,
            ad1: null,
            subdate: this.subtractYears(new Date(), 16),
            roles: null
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getAdminListOnly();
        this.getTitles();
        this.getStates();
        this.roles = [
            { label: 'Owner', value: "1" },
            { label: 'Admin', value: "2" },
            { label: 'Co-ordinater', value: "3" },
            { label: 'Billing', value: "4" },
        ];
    },
    watch: {
        dob(e) {
            if (this.age != null && this.age != 0) {
                this.age = this.calculateAge(e);
            }
        },
    },
    validations() {
        let validation = {
            title: { required: helpers.withMessage('Please select title', required) },
            userfirstname: { required: helpers.withMessage('Please enter first name', required), alpha: helpers.withMessage('Please input alphabet characters only', alpha) },
            usermiddlename: { alpha: helpers.withMessage('Please input alphabet characters only', alpha) },
            userlastname: { required: helpers.withMessage('Please enter last name', required), alpha: helpers.withMessage('Please input alphabet characters only', alpha) },
            usermobile: { required: helpers.withMessage('Please enter mobile number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
            userEmail: { required: helpers.withMessage('Please enter email id', required), email: helpers.withMessage('Email id is invalid', email) },
            gender: { required: helpers.withMessage('Please select gender', required) },
            pincode: { required: helpers.withMessage('Please enter pin code', required) },
            role: { required: helpers.withMessage('Please select user role', required) },
            state: { required: helpers.withMessage('Please select state', required) },
            city: { required: helpers.withMessage('Please select city', required) },
            address: { required: helpers.withMessage('Please enter address', required) },
            dob: { required: helpers.withMessage('Please enter date of birth', required) },
        };
        return validation;
    },
    methods: {
        getAssignedRoles(val) {
            if (val != '' && val != null && val != '0') {
                var res1 = val.split(',').map(Number);
                var res = Array.from(res1);
                let array = [];
                for (let i = 0; i < res.length; i++) {
                    for (let j = 0; j < this.roles.length; j++) {
                        if (this.roles[j].value == res[i]) {
                            array.push(this.roles[j].label);
                        }
                    }
                }
                return array.join(",");
            }
        },

        subtractYears(date, years) {
            date.setFullYear(date.getFullYear() - years);
            return date;
        },

        calculateAge(date) {
            var today = new Date();
            var age = today.getYear() - date.getYear();
            return age;
        },

        getAdminListOnly(e) {
            this.ApiService.getAdminListOnly(e).then((data) => {
                if (data.status == 200) {
                    this.adminList = data.data.records;
                    if (data.data.records.length == 1) {
                        this.getAdminEmpDetails({ value: data.data.records[0] });
                        this.emp_name = data.data.records[0];
                    }
                } else {
                    this.adminList = null;
                }
            });
        },

        getTitles(e) {
            this.ApiService.getTitles(e).then((data) => {
                if (data.status == 200) {
                    this.titleList = data.data;
                } else {
                    this.titleList = null;
                }
            });
        },

        getAdminEmpDetails(e) {
            this.userdatadisplayflag = true;
            this.loading = true;
            if (e) {
                this.stateID = e.value.maa8;
            }
            if (e) {
                this.getCity({ value: e.value.maa8 })
            }
            this.checkMobileAlreadyExist = false;
            if (e) {
                this.page_no = 0;
                this.admin_id = e.value.value;
            }
            this.ApiService.getAllAdminUserDetails({ clientId: this.admin_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.EmpDetailsList = data.data.result;
                    this.EmpTotalRecords = data.data.count;
                    this.loading = false;
                    this.admin_list_status = true;
                    this.host_name = '';
                    this.host_id = '';
                    this.branch_name = '';
                    if (e) {
                        this.getHostListOnly(e);
                    }
                }
            });
        },

        onPageChange(e) {
            this.loading = true;
            if (this.admin_list_status) {
                this.page_no = e.page;
                this.getAdminEmpDetails();
            }
            if (this.host_list_status) {
                this.page_no = e.page;
                this.getHostEmpDetails();
            }
            if (this.branch_list_status) {
                this.page_no = e.page;
                this.getHostBranchEmpDetails();
            }
        },

        getHostListOnly(e) {
            this.ApiService.getHostListOnly({ clientId: e.value.value }).then((data) => {
                if (data.status == 200) {
                    this.hostList = data.data.records;
                } else {
                    this.hostList = null;
                }
            });
        },

        getHostEmpDetails(e) {
            this.loading = true;
            if (e) {
                this.page_no = 0;
                this.host_id = e.value.value;
            }
            this.ApiService.getHostUserDetails({ clientId: this.admin_id, hostId: this.host_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.EmpDetailsList = data.data;
                    this.EmpTotalRecords = data.count;
                    this.loading = false;
                    this.userdatadisplayflag = true;
                    this.admin_list_status = false;
                    this.host_list_status = true;
                    this.branch_name = '';
                    if (e) {
                        this.getHostBranchListOnly(e);
                    }
                }
            });
        },

        getHostBranchListOnly(e) {
            this.host_id = e.value.value;
            this.ApiService.getHostBranchesOnly({ clientId: this.admin_id, hostId: e.value.value }).then((data) => {
                if (data.status == 200) {
                    this.branchList = data.data;
                } else {
                    this.branchList = null;
                }
            });
        },

        getHostBranchEmpDetails(e) {
            if (e) {
                this.page_no = 0;
                this.branch_id = e.value.value;
            }
            this.loading = true;
            this.ApiService.getHostBranchesUserDetails({ clientId: this.admin_id, hostId: this.host_id, branchId: this.branch_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.EmpDetailsList = data.data.result;
                    this.EmpTotalRecords = data.data.count;
                    this.loading = false;
                    this.userdatadisplayflag = true;
                    this.admin_list_status = false;
                    this.host_list_status = true;
                    this.branch_list_status = true;
                }
            });
        },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        getStates(ev) {
            this.ApiService.getStates(ev).then((items) => {
                if (items.success === true) {
                    this.statesList = items.data;
                } else {
                    this.statesList = [];
                }
            });
        },

        selectAdminCity(ev) {
            this.getCity(ev);
        },

        getCity(e) {
            if (e.value) {
                this.ApiService.getCities({ state_id: e.value }).then((items) => {
                    if (items.status === 200) {
                        this.citiesList = items.data;
                    } else {
                        this.citiesList = [];
                    }
                });
            }
        },

        selectAdminArea(e) {
            if (e.value) {
                this.ApiService.getAreaList({ cityId: e.value }).then((items) => {
                    if (items.status === 200) {
                        this.areaList = items.data;
                    } else {
                        this.areaList = [];
                    }
                });
            }
        },

        onAreaChange(e) {
            this.pincode = e.value.mam4;
        },

        getAdminRole() {
            this.ApiService.getAdminUserRoles({ clientId: this.admin_id }).then((items) => {
                if (items.status === 200) {
                    this.adminRoleList = items.data;
                } else {
                    this.adminRoleList = [];
                }
            });
        },

        async onSelectProfile(e) {
            this.profileImage = e.files[0];
            const file = e.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.profileImageError = 'File size cannot exceed more than 2MB';
                this.profileImage = '';
            } else if (!file.name.match(allowedExtensions)) {
                this.profileImageError = 'Invalid file type';
                this.profileImage = '';
            } else {
                this.profileImageError = '';
                if (file && file.type.includes('image/')) {
                    this.profileUrl = { url: file.objectURL, name: file.name };
                    this.profileImage = e.files[0];
                } else {
                    this.profileImageError = 'Invalid file type';
                    this.profileImage = '';
                }
            }
        },

        openCreateUserPopUp() {
            this.ad1 = null;
            this.title = '';
            this.userfirstname = '';
            this.usermiddlename = '';
            this.userlastname = '';
            this.usermobile = '';
            this.userEmail = '';
            this.gender = '';
            this.state = '';
            this.city = '';
            this.area = '';
            this.address = '';
            this.pincode = '';
            this.role = '';
            this.profileUrl = null;
            this.profileImage = null;
            this.profileImageError = '';
            this.userprofilefile = null;
            this.dob = '';
            this.checkMobileAlreadyExist = false;
            this.warningMsg = '';
            var res = Number(this.stateID);
            this.openCreateUserPopUpStatus = true;
            let data = this.statesList.filter(function (item) {
                return item.value == res;
            });
            if (data.length > 0) {
                this.state = data[0];
            }
        },

        checkMobileNumber(e) {
            if (e.length >= 10) {
                this.showLoader = true;
                this.ApiService.checkUserMobileisAlreadyPresent({ clientId: this.admin_id, mobileNumber: e }).then((data) => {
                    if (data.status == 200) {
                        if (data.data != null) {
                            this.warningMsg = data.message;
                            this.checkMobileAlreadyExist = false;
                            this.showLoader = false;
                        } else {
                            this.getAdminRole();
                            this.checkMobileAlreadyExist = true;
                            this.showLoader = false;
                            this.warningMsg = '';
                        }
                    } else {
                        var successMsg = data.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: successMsg, life: 3000 });
                        this.checkMobileAlreadyExist = false;
                        this.showLoader = false;
                    }
                });
            }
        },

        createUpdateAdminUser() {
            this.submitted = true;
            this.v$.$validate();
            let formData = new FormData();
            if (this.ad1 != null) {
                formData.append('ad1', this.ad1);
            }
            formData.append('clientId', this.admin_id);
            formData.append('ad4', this.title.value);
            formData.append('ad5', this.title.label);
            formData.append('ad6', this.userfirstname);
            if (this.usermiddlename) {
                formData.append('ad7', this.usermiddlename);
            }
            formData.append('ad8', this.userlastname);
            formData.append('ad12', moment(this.dob).format('YYYY-MM-DD'));
            formData.append('ad13', this.age);
            formData.append('ad14', this.gender);
            formData.append('ad15', this.usermobile);
            formData.append('ad18', this.userEmail);
            if (this.profileImage) {
                formData.append('ad22', this.profileImage);
            }
            formData.append('ad26', this.role.value);
            formData.append('ad27', this.role.label);
            formData.append('ad29', this.role.at3);
            formData.append('ad30', this.address);
            formData.append('ad31', this.area.value);
            formData.append('ad32', this.area.label);
            formData.append('ad33', this.city.value);
            formData.append('ad34', this.city.label);
            formData.append('ad35', this.state.value);
            formData.append('ad36', this.state.label);
            formData.append('ad37', this.pincode);
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.createUpdateAdminUsers(formData).then((items) => {
                    if (items.success) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getAdminEmpDetails();
                        this.openCreateUserPopUpStatus = false;
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        async editAdminUserPopUPOpen(data) {
            await this.getCity({value: data.ad35});
            await this.selectAdminArea({ value: data.ad33 });
            await this.getAdminRole({ clientId: this.admin_id })
            this.openCreateUserPopUpStatus = true;
            this.checkMobileAlreadyExist = true;
            this.ad1 = data.ad1;
            this.title = { value: data.ad4, label: data.ad5 };
            this.userfirstname = data.ad6;
            this.usermiddlename = data.ad7;
            this.userlastname = data.ad8;
            this.usermobile = data.ad15;
            this.userEmail = data.ad18;
            this.gender = data.ad14;
            this.state = { value: data.ad35, label: data.ad36 };
            this.city = { value: data.ad33, label: data.ad34 };
            this.area = { value: data.ad31, label: data.ad32, mam4: data.ad37 };
            this.address = data.ad30;
            this.pincode = data.ad37;
            this.role = { value: data.ad26, label: data.ad27, at3: data.ad29 };
            if (data.ad12 != null && data.ad12 != "0000-00-00") {
                this.dob = new Date(data.ad12);
            }
            this.userprofilefile = data.ad19;
        }
    },
};
</script>
<style scoped lang="scss">
.createbtn {
    text-transform: uppercase;
    margin-left: 380px;
}
</style>
